<template>
  <div class="interactive-lessons v2-container-small">
    <h2>
      на&nbsp;нашей платформе более <span>10&nbsp;000+</span> интерактивных <span>уроков</span>
    </h2>
    <h5 class="text-secondary">
      Над разработкой трудится крутая команда специалистов, а&nbsp;наши
      методисты ежедневно наполняют ее&nbsp;уроками и&nbsp;заданиями
    </h5>

    <div class="interactive-lessons__items">
      <div class="interactive-lessons__btns">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          :class="['m-btn', 'interactive-lessons__btn', {
            'interactive-lessons__btn--active': active === index,
          }]"
          :style="active === index ? { backgroundColor: button.color } : {}"
          @click="active = index"
        >
          <b v-html="button.title" />

          <p
            :class="[
              'interactive-lessons__tx',
              'interactive-lessons__tx--' + index,
              { 'interactive-lessons__tx--active' : active === index }
            ]"
            :style="{
              height: active !== index ? 0 : getHeight(index),
              'margin-top': active !== index ? '0px' : '20px',
            }"
            v-html="button.description"
          />
        </button>
      </div>

      <div class="interactive-lessons__video">
        <div
          class="interactive-lessons__video_head"
          :style="{ backgroundColor: currentButton.color }"
        >
          {{ currentButton.videoTitle }}
        </div>

        <iframe
          v-if="currentButton.youtube"
          class="interactive-lessons__video_body"
          :src="currentButton.video"
          :title="currentButton.title"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; web-share"
          allowfullscreen
        />
        <video
          v-else
          class="interactive-lessons__video_body"
          :poster="currentButton.preview"
          controls
          :src="currentButton.video"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InteractiveLessons',

  data: () => ({
    buttons: [
      {
        title: 'Уроки доступны 24/7',
        description: `
          Прошедшие уроки доступны в&nbsp;записи и&nbsp;всегда под рукой на&nbsp;платформе.
          К&nbsp;каждому уроку есть презентации, тренажеры и&nbsp;домашки
        `,
        color: '#61DDBC',
        videoTitle: 'Русская литература второй половины 19 века',
        preview: '/v2/videos/record.webp',
        video: 'https://www.youtube.com/embed/RASkhQRHVpc?si=kC4DLZBKFlh5k3kf&amp;start=910',
        youtube: true,
      },
      {
        title: 'Интерактивные задания‑тренажеры',
        description: `
          В&nbsp;каждом уроке:

          <span>20+&nbsp;интерактивных тренажеров</span>
          <span>5&nbsp;заданий для самопроверки &laquo;на&nbsp;оценку&raquo;</span>
        `,
        color: '#B3A4EE',
        videoTitle: 'Программирование на  Python',
        preview: '/v2/main/sfe/comfortable/interactive.webp',
        video: '/v2/videos/интерактивн задание.mp4',
        youtube: false,
      },
      {
        title: 'Лабораторные и&nbsp;практические работы',
        description: `
          Виртуальные лабораторные работы по&nbsp;химии, физике, биологии и&nbsp;информатике
        `,
        color: '#FB836F',
        videoTitle: 'Изучение химических реакций',
        preview: '/v2/main/sfe/comfortable/lab.webp',
        video: '/v2/videos/Лабораторки.mp4',
        youtube: false,
      },
    ],
    active: null,
  }),
  computed: {
    currentButton() {
      return this.buttons[this.active] || {};
    },
  },
  mounted() {
    this.active = 1;
  },
  methods: {
    getHeight(index) {
      if (process.browser) {
        const accBody = document.querySelector(`.interactive-lessons__tx--${index}`)?.scrollHeight || '100%';
        return `${accBody}px`;
      }

      return '0';
    },
  },
};
</script>

<style scoped lang="scss">
.interactive-lessons {
  h2 {
    color: $black;
    font-size: 45px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1.35px;
    text-transform: uppercase;
    margin-bottom: 20px;
    max-width: 750px;

    @include media-down(laptop) {
      font-size: 32px;
    }

    @include media-down(tablet) {
      font-size: 26px;
      letter-spacing: -0.52px;
      text-align: center;
      max-width: 400px;
      margin: 0 auto 12px;
    }

    span {
      color: #ff7233;
    }
  }

  .text-secondary {
    font-variant-numeric: lining-nums proportional-nums;
    max-width: 700px;
    margin-bottom: 55px;

    @include media-down(laptop) {
      font-size: 26px;
    }

    @include media-down(tablet) {
      text-align: center;
      font-size: 20px;
      margin-bottom: 36px;
    }
  }

  .interactive-lessons__items {
    display: flex;
    gap: 40px;
    align-items: flex-start;

    @include media-down(laptop) {
      flex-direction: column;
      align-items: center;
    }
  }

  .interactive-lessons__btns {
    width: 100%;
    max-width: 400px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include media-down(laptop) {
      max-width: 800px;
    }
  }

  .interactive-lessons__btn {
    border-radius: 20px;
    border: 2px dashed $black;
    background-color: transparent;
    padding: 23px 28px 28px;

    color: $black;
    font-size: 30px;
    font-weight: 700;
    line-height: 100%;
    text-align: left;

    transition-duration: 400ms;
    transition-timing-function: ease-out;

    @include media-down(tablet) {
      font-size: 25px;
      padding: 14px;
    }

    @media (hover: hover) {
      &:hover {
        border-style: solid;
      }
    }

    b {
      position: relative;
      padding-right: 21px;
      display: block;

      @include media-down(tablet) {
        padding-right: 17px;
      }

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        display: block;

        background-image: url('/v2/school/interactive-lessons/arrow.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @include media-down(tablet) {
          width: 11px;
        }
      }
    }

    p {
      margin-top: 20px;

      overflow: hidden;

      transition: 0.2s ease-out;

      font-variant-numeric: lining-nums proportional-nums;
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;

      @include media-down(tablet) {
        font-size: 18px;
        line-height: 120%;
      }

      ::v-deep span {
        display: block;
        padding: 0 30px;
        position: relative;

        @include media-down(tablet) {
          padding: 0 20px;
        }

        &:before {
          content: '';
          position: absolute;
          left: 15px;
          top: 15px;
          height: 4px;
          width: 4px;
          display: block;
          background-color: $black;
          border-radius: 50%;

          @include media-down(tablet) {
            left: 10px;
            top: 10px;
          }
        }

        &:first-child {
          margin-top: 20px;

          @include media-down(tablet) {
            margin-top: 0;
          }
        }
      }
    }
  }

  .interactive-lessons__btn--active {
    box-shadow: 4px 4px 0 0 $black;
    border-style: solid;
  }

  .interactive-lessons__video {
    flex: 1;
    max-width: min(800px, 100%);
    aspect-ratio: 10 / 7;
    position: relative;
    border-radius: 20px;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 0 $black;
    overflow: hidden;

    @include media-down(tablet) {
      display: none;
    }

    .interactive-lessons__video_head {
      position: absolute;
      left: 0;
      top: 0;
      padding: 6px 13px 10px;
      width: 100%;
      border-bottom: 2px solid $black;

      color: $black;
      font-variant-numeric: lining-nums proportional-nums;
      font-size: 18px;
      font-weight: 700;
      line-height: 130%;

      transition-duration: 400ms;
      transition-timing-function: ease-out;
    }

    .interactive-lessons__video_body {
      height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
      background-color: $black-color;
    }
  }
}
</style>
