var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"interactive-lessons v2-container-small"},[_vm._m(0),_vm._v(" "),_c('h5',{staticClass:"text-secondary"},[_vm._v("\n    Над разработкой трудится крутая команда специалистов, а наши\n    методисты ежедневно наполняют ее уроками и заданиями\n  ")]),_vm._v(" "),_c('div',{staticClass:"interactive-lessons__items"},[_c('div',{staticClass:"interactive-lessons__btns"},_vm._l((_vm.buttons),function(button,index){return _c('button',{key:index,class:['m-btn', 'interactive-lessons__btn', {
          'interactive-lessons__btn--active': _vm.active === index,
        }],style:(_vm.active === index ? { backgroundColor: button.color } : {}),on:{"click":function($event){_vm.active = index}}},[_c('b',{domProps:{"innerHTML":_vm._s(button.title)}}),_vm._v(" "),_c('p',{class:[
            'interactive-lessons__tx',
            'interactive-lessons__tx--' + index,
            { 'interactive-lessons__tx--active' : _vm.active === index }
          ],style:({
            height: _vm.active !== index ? 0 : _vm.getHeight(index),
            'margin-top': _vm.active !== index ? '0px' : '20px',
          }),domProps:{"innerHTML":_vm._s(button.description)}})])}),0),_vm._v(" "),_c('div',{staticClass:"interactive-lessons__video"},[_c('div',{staticClass:"interactive-lessons__video_head",style:({ backgroundColor: _vm.currentButton.color })},[_vm._v("\n        "+_vm._s(_vm.currentButton.videoTitle)+"\n      ")]),_vm._v(" "),(_vm.currentButton.youtube)?_c('iframe',{staticClass:"interactive-lessons__video_body",attrs:{"src":_vm.currentButton.video,"title":_vm.currentButton.title,"frameborder":"0","allow":"accelerometer; clipboard-write; encrypted-media; gyroscope; web-share","allowfullscreen":""}}):_c('video',{staticClass:"interactive-lessons__video_body",attrs:{"poster":_vm.currentButton.preview,"controls":"","src":_vm.currentButton.video}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("\n    на нашей платформе более "),_c('span',[_vm._v("10 000+")]),_vm._v(" интерактивных "),_c('span',[_vm._v("уроков")])])
}]

export { render, staticRenderFns }